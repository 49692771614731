import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import './nos-garages.scss'
import SliderGarage from '../components/slider-garage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

export default function NosGarages() {

    return (
        <Layout>
            <div id="nos-garages">
                <section className="page-title">
                    <StaticImage
                        src="../images/fond-contact.jpg"
                        alt=""
                        placeholder="blurred"
                    />
                    <div className="auto-container relative">
                        <div className="content-box">
                            <div className="content-wrapper">
                                <div className="title">
                                    <h1>Nos Garage</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay_absolute_6" />
                </section>

                <section className="garages">
                    <div className="container">
                        <div className="width50 textes">
                            <h2>Garage / Carrosserie de Beaufort</h2>
                            <div className="flex_wrap blurbs">
                                <div className="width50 blurb">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                    <a href="https://goo.gl/maps/wsNE2UXun8AoTPsaA" target="_blank" rel="noreferrer">
                                        40 route de Domelin<br />
                                        73270 Beaufort
                                    </a>
                                </div>
                                <div className="width50 blurb">
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                    <div>
                                        <p className="text"><a href="tel:+33479383324">04 79 38 33 24</a></p>
                                        <p className="text"><a href="mailto:avocat.carrosserie@orange.fr">avocat.carrosserie@orange.fr</a></p>
                                        <p className="text"><a href="mailto:joel.avocat@wanadoo.fr">joel.avocat@wanadoo.fr</a></p>
                                    </div>
                                </div>
                                <div className="width50 blurb">
                                    <FontAwesomeIcon icon={faClock} />
                                    <div>
                                        <p className="text">Du lundi au vendredi</p>
                                        <p className="text">8h - 12h / 13h30 - 18h30</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="width50 slide_width">
                            <SliderGarage />
                        </div>
                    </div>
                </section>

                <section className="garages">
                    <div className="container">
                        <div className="width50 image">
                            <StaticImage
                                src="../images/garage-saisies.jpg"
                                alt="Garage Avocat de Beaufort"
                                placeholder="blurred"
                            />
                        </div>
                        <div className="width50 textes">
                            <h2>Garage des Saisies</h2>
                            <div className="flex_wrap blurbs">
                                <div className="width50 blurb">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                    <a href="https://goo.gl/maps/rSh4Kscu5B6cAmdbA" target="_blank" rel="noreferrer">
                                        Rte du Mont Bisanne<br />
                                        73620 Hauteluce
                                    </a>
                                </div>
                                <div className="width50 blurb">
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                    <div>
                                        <p className="text"><a href="tel:+33479399034">04 79 39 90 34</a></p>
                                        <p className="text"><a href="mailto:joel.avocat@wanadoo.fr">joel.avocat@wanadoo.fr</a></p>
                                    </div>
                                </div>
                                <div className="width50 blurb">
                                    <FontAwesomeIcon icon={faClock} />
                                    <div>
                                        <p className="text">
                                            Ouvert seulement du<br />
                                            20 décembre au 20 avril
                                        </p>
                                        <p className="text">Ouvert le samedi et le dimanche</p>
                                        <p className="text">8h - 12h / 13h30 - 17h</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}