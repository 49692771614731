import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./slider-garage.scss"

export default function SliderGarage() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        autoplaySpeed: 9000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        arrows: false,
        fade: true
    }

    return (
        <div className="slider_garage">
            <Slider {...settings}>

                <StaticImage
                    src="../images/garage-beaufort.jpg"
                    alt="Garage AVOCAT Beaufort"
                    placeholder="blurred"
                />

                <StaticImage
                    src="../images/carosserie-beaufort.JPG"
                    alt="Carrosserie Garage AVOCAT Beaufort"
                    placeholder="blurred"
                />

            </Slider>
        </div>
    )
}